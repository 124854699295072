import React from "react";
import "./Rule.css";


class Rule extends React.Component{
    render(){
        return(
            <div className="Rule">
                <div className="pattern">
                   <div className="containers">
                        <div className="pattern-inner"></div>
                   </div>
                </div>
            </div>
        );
    }

}

export default Rule;