import React from "react";
import "./Options.css";
import Buttons from "../Buttons/Buttons";

class Options extends React.Component{
    constructor(props){
        super(props);
        this.state={
            options: {
                MAP:["LinkedIn","Behance","Twitter","Github","TICGN Article Archive"],
                QUEST:["Citrus Hack","TypeMoon","Ravenous", "Dripp Weather App", "Stitch Messaging App", "Highlander Gaming","Cuphead","Escape VR", "Asteroids","TIC Games Network","P2P Filesystem"],
                ITEM:[""],
                WEAPON:["Visual Studio Code","Linux for Windows Subsystem", "Ubuntu","Linux","Node Package Manager", "Terminal","Github"],
                SKILL:["HTML","CSS","Javascript","React.js", "Bootstrap","Responsive Design", "Web Deisgn","Web Development","Wordpress","Editing","C++","VR","Unity"],
                DATA:["Highlander Con","/r/Cuphead","Dream for Schools"],
                SYSTEM:["Profile","Load","Save"]
            },
            clicked: ""
        }

    
        
    }

    componentDidMount(){

    }


    handleClick(e){
        this.setState({
            clicked: e
        });
        this.props.OnPassUp(e);
    }
    render(){

        //Find the key in the obj the options obj that is the same as the selected header menu option.
        //Get it's corresponding array and display it 
        let displayButtons = <div></div>
        if(this.props.selection !== "Option Select"){
            let obj = this.state.options;
            for(let keys in this.state.options){
                if(this.props.selection === keys){
                    displayButtons = obj[keys].map((options,index) =>{
                        let boundItemClick = this.handleClick.bind(this, options);
                        return <Buttons key={index} OnPress={boundItemClick} buttonName={options} width={"15em"} padding={"4px"} clicked={this.state.clicked}/>
                    })
                }
            }
        }
        

        return(
            <div className="Options">
                <ul>
                    {displayButtons}
                </ul>
            </div>
        );
    }
}

export default Options;