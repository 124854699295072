import React from "react";
import "./Body.css";
import Rule from "../Rule/Rule";
import Content from "../Content/Content"



import Scanbar from "./Scanbar/Scanbar";
import Menu from "../Menu/Menu"


class Body extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            selection: "Option Select"
        };
        this.handleSelection = this.handleSelection.bind(this);

    }

    handleSelection(e){
        console.log("3) Body - Selection passed up " + e);
        this.setState({
            selection: e,
        })
    }
    


    render(){
        return(
            <div className="Body">
                <Scanbar></Scanbar>
                <Menu passUp={this.handleSelection}></Menu>
                <Rule></Rule>
                <Content title={this.state.selection}/>
                <Rule></Rule>
                
            </div>
        );
    }
}


export default Body;
