import React from "react";
import "./Scanbar.css"

class Scanbar extends React.Component{

    constructor(props){
        super(props);
        this.state={
            bot: 0,
            padding: 0
        }
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {

        //Added a Timer ID to this because doesn't necessarily work with props or state
        this.timerID = setInterval(
          () => this.tick(),
          3000
        );
      }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }
    

    tick() {
        let size = 1 + Math.floor(Math.random() * 35);
        let location = 1 + Math.floor(Math.random() * 100);
        this.setState({
          padding: size,
          bot: location
        });
        console.log("Tick")
    }

    render(){
        return(
            <div className="Scanbar" style={{ padding: this.state.padding + "%", bottom: this.state.bot + "%" }}>
            </div>
        );
    }
}

export default Scanbar;