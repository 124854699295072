import React from "react";
import "./Menu.css";
import Buttons from "../Buttons/Buttons";
import Rule from "../Rule/Rule"


class Menu extends React.Component{
    constructor(props){
        super(props);
        this.state={
            menuClicked: "",
            option: "",
            optionClicked: "Select an option",
            modalClicked: 1,
        }

        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount(){

    }

    handleClick(e) {
        console.log("2) Successfully Brought to Menu and setting State: " + e);
        this.setState({
          menuClicked: e,
          optionClicked: "Select an option",
          modalClicked: 1
        });
        this.props.passUp(e);
    }



    render(){
        return(
            <div className = "Menu">
                <Buttons buttonName="MAP" OnPress={this.handleClick} clicked={this.state.menuClicked} />
                <Buttons buttonName="QUEST" OnPress={this.handleClick} clicked={this.state.menuClicked}/> {/*Quests will show projects complete aka resume*/}
                <Buttons buttonName="ITEM" OnPress={this.handleClick} clicked={this.state.menuClicked} />  {/*Maybe show music options?*/}
                <Buttons buttonName="WEAPON" OnPress={this.handleClick} clicked={this.state.menuClicked} /> {/*Quests will show projects complete aka resume*/}
                <Buttons buttonName="SKILL" OnPress={this.handleClick} clicked={this.state.menuClicked} /> {/*Quests will show projects complete aka resume*/}
                <Buttons buttonName="DATA" OnPress={this.handleClick} clicked={this.state.menuClicked} /> {/*Show social media*/}
                <Buttons buttonName="SYSTEM" OnPress={this.handleClick} clicked={this.state.menuClicked} /> {/*Save Game See my Stats*/}
            </div>
        );
    }
}

export default Menu;