import React from "react";
import "./Buttons.css";

import SoundFile from "../Sound/core_16.ogg";
import SoundFileClick from "../Sound/Click.ogg"

class Buttons extends React.Component{
    constructor(props){
        super(props);
        this.state={
            click: false,
            enter: 0
        }
        this.handleName = this.handleName.bind(this);
        this.entered = this.entered.bind(this);
        this.exit = this.exit.bind(this);
    }


    //When a button is clicked the the button name that was selected is sent up to see which button was selected.
    handleName(e){
        console.log("1) First Step Successfully Clicked " + this.props.buttonName);
        const name = this.props.buttonName;
        this.props.OnPress(name);
    }

    
    entered(){
        this.setState({
            enter: 1
        })
        console.log("entered successfully");
     
        return;
    }

    exit(){
        this.setState({
            enter: 0
        });
        console.log("left successfully");
        return;
    }




    render(){

        //Default color of buttons
        var backgroundColor = "#D9D9D9";
        var blockBackColor = "#5C5C5C";
        var top = "solid 2px transparent";
        var bot = "solid 5px transparent";
        var fColor = "#5C5C5C";
        var click = <div></div>

        //Handle click color change of buttons and set sound
        if(this.props.clicked === this.props.buttonName){
            //console.log("Actually Changed!")
            top = "solid 2px #5C5C5C";
            bot = "solid 2px #5C5C5C";
            backgroundColor= "#5C5C5C";
            blockBackColor = "#D9D9D9";
            fColor="#D9D9D9";
            click = <audio  src={SoundFileClick} autoPlay/>
        }/*Don't need a else if to reset color because on change automatically rests variables. */


        var sound= <div></div>
        if(this.state.enter){
            sound = <audio  src={SoundFile} autoPlay/>
        }else{
            sound= <div></div>
        }


        


        return(
            <div className="Buttons" onMouseEnter={this.entered} onMouseLeave={this.exit} onClick={this.handleName} style={{borderTop:top, borderBottom:bot}}>
                {sound}
                {click}
                <div className = "Group-Button" style={{backgroundColor:backgroundColor, color:fColor}}>
                    <div className="Ico-Block" style={{backgroundColor: blockBackColor}}>
                        <img src = "" />
                    </div>
                    <div className="Button-Name">
                        {this.props.buttonName}
                    </div>
                </div>
            </div>
        );
    }

}

export default Buttons;
