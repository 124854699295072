import React from "react";
import "./Card.css"

class Card extends React.Component{
    constructor(props){
        super(props);
        this.state={
        }
    }

    componentDidMount(){

    }

    render(){
        let link1 = <div></div>;
        let link2 =<div></div>;
        let link3 =<div></div>;
        let link4 = <div></div>
        if(this.props.R1 !==""){
            link1 = 
            <div className="left-right">
                <div className="left">
                    {this.props.L1}
                </div>
                <div className="right">
                    <a href={this.props.R1}>{this.props.L1Name}</a>
                </div>
            </div>;
        }
        if(this.props.R2 !==""){
            link2 = 
            <div className="left-right">
                    <div className="left">
                        {this.props.L2}
                    </div>
                    <div className="right">
                        <a href={this.props.R2}>{this.props.L2Name}</a>
                    </div>
            </div>;
               
        }
        if(this.props.R3 !==""){
            link3 = 
            <div className="left-right">
                <div className="left">
                    {this.props.L3}
                </div>
                <div className="right">
                    <a href={this.props.R3}>{this.props.L3Name}</a>
                </div>
            </div>;
        }

        if(this.props.R4 !== ""){
            link4 = 
                <div className="left-right">
                    <div className="left">
                        {this.props.L4}
                    </div>
                    <div className="right">
                        <a href={this.props.R4}>{this.props.L4Name}</a>
                    </div>
                </div>
        }
        return(
            <div className="Card">
                <div className="cardTitle">{this.props.name}</div>
                <div className="left-right">
                    <div className="left">Project Description: </div>
                    <div className="right">{this.props.type}</div>
                </div>
                <div className="containers">
                    <hr></hr>
                </div>
                <div className="information">
                    {this.props.desc}
                </div>
                <div className="left-right">
                    <div className="left">Tech: </div>
                    <div className="right">{this.props.tech}</div>
                </div>
                <div className="left-right">
                    <div className="left">Tools: </div>
                    <div className="right">{this.props.tools}</div>
                </div>


                <div className="containers">
                    <hr></hr>
                </div>

                {link1}
                {link2}
                {link3}
                {link4}
                
                

                <div className="left-right center">
                    <div className="boxes"></div><div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div> <div className="boxes"></div>
                </div>
                <div className="containers">
                    <hr></hr>
                </div>
            </div>
        );
    }



}

export default Card;