import React from "react";
import "./Content.css"
import Options from "../Options/Options";
import Card from "../Card/Card";

class Content extends React.Component{
    constructor(props){
        super(props);
        this.state={
            optionSelected: 0,
            subOptionSelected: 0,
            title: "Option Select",
            subOpTitle:"", //Adding sub options requires the option to be listed in Options.js
            CitrusHack:{
                    description: "I worked with the Citrus Hack hackathon committee to build an event and website that would be used by thousands of student engineers on the UCR campus.",
                    type:"Web Developer",
                    tech:"HTML, CSS, JS, Bootstrap",
                    tools:"VS Code, Illustrator",
                    design:"https://www.behance.net/gallery/68991005/Cutie-Hack",
                    github: "https://github.com/citrushack/CutieHack2018/graphs/contributors"
            },
            TypeMoon:{
                description: "A simple Twitter like messaging app. The purpose of this project is to become familiar with AWS services such as Hosting, CI/CD, App Sync, GraphQL, and Authentication. The app is currently a work in progress.",
                type: "Web Developer",
                tech:"React, AWS, Dynamo DB, Auth",
                tools:"Amplify, VS Code",
                site:"https://www.typemoon.app"
            },
            Ravenous:{
                description:"Ravenous is a react app that helps you locate food in your area. Using the Yelp API, the app will locate fast food restaurants along with their rating.",
                tech: "React, Responsive, Yelp API",
                tools: "VS Code,Photoshop,Illustrator",
                type:"Web Dev",
                github:"https://github.com/cris178/Ravenous",
                site:"https://ravenous-3c955.firebaseapp.com/"
            },
            Asteroids:{
                    description:"Built Asteroids as a custom project for an Embedded Systems course.",
                    tech:"C language",
                    tools:"ATMEL Studio, ATMEGA",
                    type:"Electrical Engineer",
                    youtube: "https://www.youtube.com/watch?v=eaqVpGNsSGg",
                    github: "https://github.com/cris178/Asteroids"
            },
            StitchMessagingApp:{ 
                description:"Built a location based Messaging app with real time video sharing. The project was built with a team using an agile philosophy with the kanban style app Trello to organize tasks.",
                type:"Web Developer",
                tech: "React,Firebase Realtime Database, Youtube API, Firebase Hosting",
                tools:"HTML, CSS, JS, Boots",
                github: "https://github.com/jasonthejewell/CS180-Group-Stitch/tree/master/team-stitch"
            },
            HighlanderGaming:{
                description:"Worked for an esports org and helped drive HLG's online presence. During my year long tenure I developed a React based mobile esports stats app and designed the landing page for Highlander Con.",
                type:"Web Developer",
                tech: "React,Firebase Hosting, AWS",
                tools: "VS Code, Photoshop",
                site: "https://stats.highlandergaming.org",
                design: "https://www.behance.net/gallery/98850669/Highlander-Con-Design"
            },
            EscapeVR:{
                description:"Escape VR is a Unity based VR project. The small experimental project has you explore a maze grave yard in order to find the keys and escape the maze.",
                type:"Web Developer",
                tech: "Unity, C#, ",
                tools:"Unity, Visual Studio",
                github: "https://github.com/cris178/EscapeVR",
            },
            Cuphead:{
                description:"I created the CSS for the [old] /r/Cuphead subreddit layout. I helped grow the community from the start to its now 50k subscribers. Additionally I moderate the community by promoting unqiue content to the forum.",
                type: "Web Design",
                tech: "CSS",
                tools: "Photoshop Illustrator",
                site: "https://www.reddit.com/r/cuphead",
                github:"https://github.com/cris178/Cuphead"
            },
            TICGamesNetwork:{
                description: "I write articles for the gaming news site The Inner Circle Games Network. I create and edit content from interviews with Xbox executives, to reporting the latest trends in gaming.",
                type: "Writing",
                tech: "Editing, Copywriting, SEO",
                tools: "Wordpress",
                site: "https://ticgamesnetwork.com/author/cristian/"
            },
            DrippWeatherApp:{
                description:"A simple weather app that gets the lows and highs in a 24 hour period",
                type:"Web Developer",
                tech: "React, Open Weather API,Bootstrap",
                tools: "VS Code",
                site: "https://drippweather.firebaseapp.com/",
                github: "https://github.com/cris178/weatherapp/tree/master/dripp"
            },P2PFilesystem:{
                description:"A P2P Filesystem for Ubuntu that does as its namesake implies, share text files with others who install this filesystem.",
                type:"Operating Systems",
                tech: "C, C++, FUSE, OpenDHT",
                tools: "Virtual Box, VSCode",
                github: "https://github.com/cris178/P2PFileSystem",
            },

            
            
        }
        this.handleClick = this.handleClick.bind(this);
    }


    
    
//When a sub option is selected we need to load the appropriate blocks
handleClick(e){
    console.log("4) Sub Option Selected: " + e);
    this.setState({
        subOptionSelected: 1,
        subOpTitle: e
    });

}
componentDidUpdate(prevProps){
    if(prevProps.title !== this.props.title){
        console.log("Prop updated: " + this.props.title);
        this.setState({
            optionSelected: 1,
            subOptionSelected: 0,
            title: this.props.title,
            subOpTitle: ""
        })
    }
}


    render(){
        //onsole.log(this.state);
        let options = <div></div>
        let subOption = <div></div>
        if(this.state.optionSelected === 1){
            options = <Options selection={this.props.title} OnPassUp={this.handleClick}/>
        }
        if(this.state.subOptionSelected === 1){
            let select = this.state.subOpTitle.replace(/\s/g, '');
            console.log("Joined Together: "+ select);
            if(this.state.subOpTitle === "TypeMoon" ||this.state.subOpTitle === "Cuphead" || this.state.subOpTitle === "Citrus Hack" || this.state.subOpTitle === "Ravenous" || this.state.subOpTitle === "Dripp Weather App" || this.state.subOpTitle === "Stitch Messaging App" ||this.state.subOpTitle === "Highlander Gaming" || this.state.subOpTitle === "Escape VR" || this.state.subOpTitle === "Asteroids" || this.state.subOpTitle === "TIC Games Network" || this.state.subOpTitle === "P2P Filesystem"){
                let descript = this.state[select].description ||"";
                let type = this.state[select].type;
                let tech = this.state[select].tech;
                let tools = this.state[select].tools;
                let site = this.state[select].site || "";
                let github = this.state[select].github || "";
                let design = this.state[select].design || "";
                //console.log(link3);
                subOption = <Card name={this.state.subOpTitle} L1={"Website:"} R1={site} L1Name={this.state.subOpTitle} L2={"Source Code:"} R2={github} L2Name={"Github"} L3={"Design:"} L3Name={"Behance"} R3={design}  desc={descript} type={type} tech={tech} tools={tools}/>
            }else if(this.state.subOpTitle === "LinkedIn" || this.state.subOpTitle === "Behance" || this.state.subOpTitle === "Twitter" ||this.state.subOpTitle === "Github" || this.state.subOpTitle === "TICGN Article Archive" ){
               if(this.state.subOpTitle === "LinkedIn"){
                    window.open("https://www.linkedin.com/in/cristian-reyes/", "_blank", "toolbar=yes,top=500,left=500,width=400,height=400");
                    this.setState({
                        subOpTitle: "",
                        subOptionSelected: 0
                    })
               }else if(this.state.subOpTitle === "Behance"){
                    window.open("https://www.behance.net/HiddenValley", "_blank", "toolbar=yes,top=500,left=500,width=400,height=400");
                    this.setState({
                        subOpTitle: "",
                        subOptionSelected: 0
                    });
                }else if(this.state.subOpTitle === "Twitter"){
                    window.open("https://twitter.com/cris178", "_blank", "toolbar=yes,top=500,left=500,width=400,height=400");
                    this.setState({
                        subOpTitle: "",
                        subOptionSelected: 0
                    });
                }else if(this.state.subOpTitle === "Github"){
                    window.open("https://github.com/cris178", "_blank", "toolbar=yes,top=500,left=500,width=400,height=400");
                    this.setState({
                        subOpTitle: "",
                        subOptionSelected: 0
                    });
                }else if(this.state.subOpTitle === "TICGN Article Archive"){
                    window.open("https://ticgamesnetwork.com/author/cristian/", "_blank", "toolbar=yes,top=500,left=500,width=400,height=400");
                    this.setState({
                        subOpTitle: "",
                        subOptionSelected: 0
                    });
                }
                
            }
            
        }
        return(
            <div className="Content">
                <div className ="title"><h1>{this.state.title}</h1></div>
                {options}
                {subOption}
            </div>
        );
    }
}

export default Content;